<template>
  <div id="background">
    <Header />
    <v-row justify="center" style="width: 100%; margin: 0px">
      <v-col
        align-self="center"
        xs="12"
        sm="12"
        md="8"
        lg="6"
        xl="4"
        style="padding: 0px"
      >
        <v-container>
          <v-snackbar v-model="snackbar" top color="green">{{
            snackbarText
          }}</v-snackbar>
          <v-snackbar v-model="errorSnackbar" top color="red" outlined>{{
            errorSnackbarText
          }}</v-snackbar>
          <v-progress-linear
            v-if="loading || ageGroupsLoading"
            indeterminate
            color="primary"
          />
          <v-card v-if="booking">
            <div
              v-if="
                booking.otpSent &&
                !booking.otpVerified &&
                !booking.isPaid &&
                !booking.isAllowed &&
                !booking.isCashAllowed
              "
            >
              <v-card-text>
                <v-alert text type="info">
                  <div class="body-2">
                    Verify your phone number to get cheaper local prices. An SMS
                    has been sent to your phone with the OTP.
                  </div>
                </v-alert>
                <div style="display: flex; align-items: center">
                  <v-text-field
                    v-model="otp"
                    label="OTP"
                    outlined
                    type="number"
                    dense
                    hide-details
                    style="flex: 1 0"
                    @keypress.enter="submitOTP()"
                  ></v-text-field>
                  <v-btn
                    color="primary"
                    class="ml-2"
                    outlined
                    :loading="otpSubmitting"
                    :disabled="otpResending"
                    @click="submitOTP()"
                    >Submit</v-btn
                  >
                  <v-btn
                    color="primary"
                    class="ml-2"
                    outlined
                    :loading="otpResending"
                    :disabled="otpSubmitting"
                    @click="resendOTP()"
                    >Resend</v-btn
                  >
                </div>
              </v-card-text>
            </div>
            <div style="padding-bottom: 10px">
              <v-card-text>
                <v-alert text type="warning" v-if="booking.isCancelled">
                  <div class="body-2">Your booking has been cancelled.</div>
                </v-alert>
                <v-alert
                  text
                  type="success"
                  v-if="
                    (booking.isPaid ||
                      booking.isAllowed ||
                      booking.isCashAllowed) &&
                    !booking.isCancelled
                  "
                >
                  Booking confirmed!
                  <div class="body-2">
                    Confirmation sent to
                    {{ booking.email ? booking.email : "" }}
                    {{ booking.email && booking.phone ? "and" : "" }}
                    {{ booking.phone ? booking.phone : "" }}
                  </div>
                </v-alert>
                <v-alert
                  text
                  type="info"
                  v-if="cancellationPolicy && booking.isPaid"
                >
                  <div class="body-2">
                    {{ cancellationPolicy }}
                  </div>
                </v-alert>
                <v-alert
                  text
                  type="info"
                  v-if="booking.isCashAllowed && !booking.isPaid"
                >
                  <div class="body-2">
                    You may pay in cash before the trip departs.
                  </div>
                </v-alert>
                <v-row
                  style="margin-left: 0px; margin-right: 0px; margin-top: 0px"
                >
                  <v-card width="100%" elevation="0" outlined>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Booking number</v-list-item-title>
                        <v-list-item-subtitle class="text-wrap" width="auto">
                          <v-row>
                            <v-col align-self="center">
                              #{{ booking.refNo }}</v-col
                            >
                            <v-col
                              v-if="configEnabled('Enable booking QR')"
                              align-self="center"
                              cols="4"
                              sm="2"
                            >
                              <div>
                                <span @click="dialog = true">
                                  <VueQrcode
                                    :value="uuid"
                                    :options="{ scale: 2 }"
                                  ></VueQrcode
                                ></span>

                                <v-dialog v-model="dialog" width="auto">
                                  <v-card
                                    max-width="400"
                                    prepend-icon="mdi-update"
                                  >
                                    <VueQrcode
                                      :value="uuid"
                                      :options="{ scale: 8 }"
                                    ></VueQrcode>
                                  </v-card>
                                </v-dialog>
                              </div>
                            </v-col>
                          </v-row>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <LinkedBooking
                      v-if="booking.returnTripUUID"
                      :uuid="booking.returnTripUUID"
                      :return="true"
                      :message="message"
                      :errorMessage="errorMessage"
                    />
                    <LinkedBooking
                      v-if="booking.outboundTripUUID"
                      :uuid="booking.outboundTripUUID"
                      :return="false"
                      :message="message"
                      :errorMessage="errorMessage"
                    />
                  </v-card>
                </v-row>
                <v-row
                  style="margin-left: 0px; margin-right: 0px; margin-top: 20px"
                >
                  <v-card width="100%" elevation="0" outlined>
                    <v-row style="margin: 12px 16px 12px 16px">
                      <v-col style="padding: 0px">
                        <div class="text-subtitle-1">
                          <ToFromLocation
                            :from="booking.trip.fromLocation"
                            :to="booking.trip.toLocation"
                            :pickupPoint="booking.pickupPoint"
                            :dropoffPoint="booking.dropoffPoint"
                          />
                        </div>
                        <div
                          class="text-body-2"
                          style="color: rgba(0, 0, 0, 0.6)"
                        >
                          {{ computeDate(booking.trip.fromTime) }}
                        </div>
                        <div
                          class="text-body-2"
                          style="color: rgba(0, 0, 0, 0.6)"
                        >
                          <v-icon dense>mdi-clock</v-icon
                          >{{ toFromString(booking.trip) }}
                        </div>
                        <div
                          class="text-body-2"
                          style="color: rgba(0, 0, 0, 0.6)"
                        >
                          <v-icon dense>mdi-ferry</v-icon>
                          <span>{{ booking.trip.boat.name }}</span>
                        </div>
                        <SeatSelection
                          v-if="configEnabled('Enable seat selection feature')"
                          :trip="booking.trip"
                          :booking="booking"
                          :message="message"
                          :errorMessage="errorMessage"
                          :getBooking="getBooking"
                        />
                        <v-alert
                          border="left"
                          type="info"
                          v-if="booking.trip.remarks"
                          dense
                          text
                        >
                          {{ booking.trip.remarks }}
                        </v-alert>
                      </v-col>
                      <v-col
                        v-if="
                          !booking.trip.departed &&
                          !booking.trip.ended &&
                          isBeforeNow(booking.trip.fromTime) &&
                          !booking.isPrivate &&
                          !booking.isCancelled &&
                          !booking.routeToStopId
                        "
                        class="shrink"
                        style="padding: 0px"
                      >
                        <ChangeBooking
                          :booking="booking"
                          :isReturnTrip="false"
                          :message="message"
                          :errorMessage="errorMessage"
                          :getBooking="getBooking"
                        />
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="booking.returnTripId"
                      style="margin: 0px 16px 12px 16px"
                    >
                      <v-col style="padding: 0px">
                        <div class="text-subtitle-1">
                          <ToFromLocation
                            :from="booking.returnTrip.fromLocation"
                            :to="booking.returnTrip.toLocation"
                            :pickupPoint="booking.dropoffPoint"
                            :dropoffPoint="booking.pickupPoint"
                          />
                        </div>
                        <div
                          class="text-body-2"
                          style="color: rgba(0, 0, 0, 0.6)"
                        >
                          {{ computeDate(booking.returnTrip.fromTime) }}
                        </div>
                        <div
                          class="text-body-2"
                          style="color: rgba(0, 0, 0, 0.6)"
                        >
                          <v-icon dense>mdi-clock</v-icon
                          >{{ toFromString(booking.returnTrip) }}
                        </div>
                        <div
                          class="text-body-2"
                          style="color: rgba(0, 0, 0, 0.6)"
                        >
                          <v-icon dense>mdi-ferry</v-icon>
                          <span>{{ booking.returnTrip.boat.name }}</span>
                        </div>
                        <SeatSelection
                          v-if="configEnabled('Enable seat selection feature')"
                          :trip="booking.returnTrip"
                          :booking="booking"
                          :message="message"
                          :errorMessage="errorMessage"
                          :getBooking="getBooking"
                        />
                        <v-alert
                          border="left"
                          type="info"
                          v-if="booking.returnTrip.remarks"
                          dense
                          text
                        >
                          {{ booking.returnTrip.remarks }}
                        </v-alert>
                      </v-col>
                      <v-col
                        v-if="
                          !booking.returnTrip.departed &&
                          !booking.returnTrip.ended &&
                          isBeforeNow(booking.returnTrip.fromTime) &&
                          !booking.isCancelled &&
                          !booking.routeToStopId
                        "
                        class="shrink"
                        style="padding: 0px"
                      >
                        <ChangeBooking
                          :booking="booking"
                          :isReturnTrip="true"
                          :message="message"
                          :errorMessage="errorMessage"
                          :getBooking="getBooking"
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-row>
                <v-row
                  style="margin-left: 0px; margin-right: 0px; margin-top: 20px"
                >
                  <v-card width="100%" elevation="0" outlined>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-wrap"
                          >Customers</v-list-item-title
                        >
                        <v-list-item-subtitle
                          v-for="customer in booking.customers"
                          :key="customer.id"
                          style="display: flex; justify-content: space-between"
                        >
                          <div>
                            {{ customer.name }}
                          </div>
                          <div>
                            {{ customer.nid }}
                          </div>
                          <div>
                            {{ customer.emergencyContact }}
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-row>
                <v-row
                  style="margin-left: 0px; margin-right: 0px; margin-top: 20px"
                >
                  <v-card width="100%" elevation="0" outlined>
                    <v-list-item v-if="booking.email">
                      <v-list-item-content>
                        <v-list-item-title class="text-wrap"
                          >Email address</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          booking.email
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="booking.phone">
                      <v-list-item-content>
                        <v-list-item-title class="text-wrap"
                          >Phone number</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          booking.phone
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-row>
                <v-row
                  v-if="booking.notes"
                  style="margin-left: 0px; margin-right: 0px; margin-top: 20px"
                >
                  <v-card width="100%" elevation="0" outlined>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-wrap"
                          >Other details</v-list-item-title
                        >
                        <v-list-item-subtitle class="text-wrap">{{
                          booking.notes
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-row>
                <v-row
                  v-if="
                    !booking.isPaid &&
                    !booking.isAllowed &&
                    !booking.isCashAllowed
                  "
                  style="margin-left: 0px; margin-right: 0px; margin-top: 20px"
                >
                  <v-card width="100%" elevation="0" outlined>
                    <v-list-item>
                      <!-- <v-list-item-content style="padding-bottom:0px">
                        <v-list-item-title class="text-wrap">Payment</v-list-item-title>
                        <v-radio-group
                          v-model="paymentInUSD"
                          style="margin-left:10px;margin-bottom:0px"
                        >
                          <v-list-item-subtitle v-if="paymentInUSD">
                            <div
                              v-for="group in existingAgeGroups"
                              :key="group.id"
                              style="display:flex;justify-content:space-between;min-width: 150px"
                            >
                              <span>{{ ageGroupBreakdownLeft(group) }}</span>
                              <span>{{ ageGroupBreakdownRight(group, true) }}</span>
                            </div>
                          </v-list-item-subtitle>
                          <v-radio
                            key="1"
                            :value="true"
                            :label="`${decimalCurrency(booking.priceInCent)} USD`"
                          ></v-radio>
                          <v-list-item-subtitle v-if="!paymentInUSD">
                            <div
                              v-for="group in existingAgeGroups"
                              :key="group.id"
                              style="display:flex;justify-content:space-between;min-width: 150px"
                            >
                              <span>{{ ageGroupBreakdownLeft(group) }}</span>
                              <span>{{ ageGroupBreakdownRight(group, false) }}</span>
                            </div>
                          </v-list-item-subtitle>
                          <v-radio
                            key="2"
                            :value="false"
                            :label="`${decimalCurrency(booking.priceInLaari)} MVR`"
                          ></v-radio>
                        </v-radio-group>
                      </v-list-item-content>-->
                      <v-list-item-content>
                        <v-list-item-title class="text-wrap"
                          >Payment</v-list-item-title
                        >
                        <v-list-item-subtitle style="padding-top: 15px">
                          <v-row
                            v-for="group in existingAgeGroups"
                            :key="group.id"
                            style="
                              display: flex;
                              justify-content: space-between;
                              min-width: 150px;
                            "
                          >
                            <v-col class="noVerticalPadding">{{
                              ageGroupBreakdownLeft(group)
                            }}</v-col>
                            <v-col class="noVerticalPadding">{{
                              ageGroupBreakdownRight(group, true)
                            }}</v-col>
                            <v-col class="noVerticalPadding">{{
                              ageGroupBreakdownRight(group, false)
                            }}</v-col>
                          </v-row>
                          <v-row
                            v-if="
                              (booking.sameDayDiscountCent &&
                                booking.sameDayDiscountCent > 0) ||
                              (booking.sameDayDiscountLaari &&
                                booking.sameDayDiscountLaari > 0)
                            "
                            style="
                              display: flex;
                              justify-content: space-between;
                              min-width: 150px;
                            "
                          >
                            <v-col style="padding-top: 0px"
                              >Same day discount</v-col
                            >
                            <v-col style="padding-top: 0px"
                              >-{{
                                decimalCurrency(booking.sameDayDiscountCent)
                              }}
                              USD</v-col
                            >
                            <v-col style="padding-top: 0px"
                              >-{{
                                decimalCurrency(booking.sameDayDiscountLaari)
                              }}
                              MVR</v-col
                            >
                          </v-row>
                          <v-row
                            style="
                              display: flex;
                              justify-content: space-between;
                              min-width: 150px;
                            "
                            class="font-weight-bold"
                          >
                            <v-col style="padding-top: 0px">Total</v-col>
                            <v-col style="padding-top: 0px"
                              >{{
                                decimalCurrency(booking.priceInCent)
                              }}
                              USD</v-col
                            >
                            <v-col style="padding-top: 0px"
                              >{{
                                decimalCurrency(booking.priceInLaari)
                              }}
                              MVR</v-col
                            >
                          </v-row>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-row>
                <v-row
                  v-if="booking.isPaid || booking.isAllowed"
                  style="
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-top: 20px;
                    margin-bottom: 0px;
                  "
                >
                  <v-card width="100%" elevation="0" outlined>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-wrap"
                          >Payment</v-list-item-title
                        >
                        <v-list-item-subtitle v-if="booking.paidInUSD">
                          <div
                            v-for="group in existingAgeGroups"
                            :key="group.id"
                            style="
                              display: flex;
                              justify-content: space-between;
                              min-width: 150px;
                            "
                          >
                            <span>{{ ageGroupBreakdownLeft(group) }}</span>
                            <span>{{
                              ageGroupBreakdownRight(group, true)
                            }}</span>
                          </div>
                          <div
                            v-if="
                              booking.sameDayDiscountCent &&
                              booking.sameDayDiscountCent > 0
                            "
                            style="
                              display: flex;
                              justify-content: space-between;
                              min-width: 150px;
                            "
                          >
                            <span>Same day trip discount</span>
                            <span>
                              <span
                                >-{{
                                  decimalCurrency(booking.sameDayDiscountCent)
                                }}
                                USD</span
                              >
                            </span>
                          </div>
                          <div
                            v-if="convenienceFee"
                            style="
                              display: flex;
                              justify-content: space-between;
                              min-width: 150px;
                            "
                          >
                            <span>Processing Fee</span>
                            <span>
                              <span
                                >{{ decimalCurrency(convenienceFee) }} USD</span
                              >
                            </span>
                          </div>
                          <div
                            style="
                              display: flex;
                              justify-content: space-between;
                              min-width: 150px;
                            "
                            class="font-weight-bold"
                          >
                            <span>Total</span>
                            <span
                              >{{
                                decimalCurrency(
                                  booking.priceInCent + convenienceFee
                                )
                              }}
                              USD</span
                            >
                          </div>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="booking.paidInMVR">
                          <div
                            v-for="group in existingAgeGroups"
                            :key="group.id"
                            style="
                              display: flex;
                              justify-content: space-between;
                              min-width: 150px;
                            "
                          >
                            <span>{{ ageGroupBreakdownLeft(group) }}</span>
                            <span>{{
                              ageGroupBreakdownRight(group, false)
                            }}</span>
                          </div>
                          <div
                            v-if="
                              booking.sameDayDiscountLaari &&
                              booking.sameDayDiscountLaari > 0
                            "
                            style="
                              display: flex;
                              justify-content: space-between;
                              min-width: 150px;
                            "
                          >
                            <span>Same day trip discount</span>
                            <span>
                              <span
                                >-{{
                                  decimalCurrency(booking.sameDayDiscountLaari)
                                }}
                                MVR</span
                              >
                            </span>
                          </div>
                          <div
                            style="
                              display: flex;
                              justify-content: space-between;
                              min-width: 150px;
                            "
                            class="font-weight-bold"
                          >
                            <span>Total</span>
                            <span
                              >{{
                                decimalCurrency(booking.priceInLaari)
                              }}
                              MVR</span
                            >
                          </div>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                          v-if="booking.isAllowed && booking.allowedType === ''"
                          >Free of charge</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-row>
                <v-row
                  v-if="booking.isCashAllowed && !booking.isPaid"
                  style="
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-top: 20px;
                    margin-bottom: 0px;
                  "
                >
                  <v-card width="100%" elevation="0" outlined>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-wrap"
                          >Payment</v-list-item-title
                        >
                        <v-list-item-subtitle>
                          <div
                            v-for="group in existingAgeGroups"
                            :key="group.id"
                            style="
                              display: flex;
                              justify-content: space-between;
                              min-width: 150px;
                            "
                          >
                            <span>{{ ageGroupBreakdownLeft(group) }}</span>
                            <span>
                              <span>{{
                                ageGroupBreakdownRight(group, false)
                              }}</span>
                              <span
                                style="margin-left: 10px; margin-right: 10px"
                                >or</span
                              >
                              <span>{{
                                ageGroupBreakdownRight(group, true)
                              }}</span>
                            </span>
                          </div>
                          <div
                            v-if="
                              booking.sameDayDiscountLaari ||
                              booking.sameDayDiscountCent
                            "
                            style="
                              display: flex;
                              justify-content: space-between;
                              min-width: 150px;
                            "
                          >
                            <span>Same day trip discount</span>
                            <span>
                              <span
                                >-{{
                                  decimalCurrency(booking.sameDayDiscountLaari)
                                }}
                                MVR</span
                              >
                              <span
                                style="margin-left: 10px; margin-right: 10px"
                                >or</span
                              >
                              <span
                                >-{{
                                  decimalCurrency(booking.sameDayDiscountCent)
                                }}
                                USD</span
                              >
                            </span>
                          </div>
                          <div
                            style="
                              display: flex;
                              justify-content: space-between;
                              min-width: 150px;
                            "
                            class="font-weight-bold"
                          >
                            <span>Total</span>
                            <span>
                              <span
                                >{{
                                  decimalCurrency(booking.priceInLaari)
                                }}
                                MVR</span
                              >
                              <span
                                style="margin-left: 10px; margin-right: 10px"
                                >or</span
                              >
                              <span
                                >{{
                                  decimalCurrency(booking.priceInCent)
                                }}
                                USD</span
                              >
                            </span>
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-row>
                <v-row
                  v-if="
                    !booking.isPaid &&
                    !booking.isAllowed &&
                    !booking.isCashAllowed &&
                    !booking.isCancelled
                  "
                  style="
                    margin-left: 0px;
                    margin-right: 0px;
                    margin-top: 20px;
                    margin-bottom: 0px;
                  "
                >
                  <v-card width="100%" elevation="0">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-wrap"
                          >Payment Options</v-list-item-title
                        >
                        <CardPayment
                          v-if="
                            (bmlGatewayAllowed || stripeGatewayAllowed) &&
                            booking.cashPayment.id === 0
                          "
                          :message="message"
                          :errorMessage="errorMessage"
                          :booking="booking"
                          :getBooking="getBooking"
                          :stripeFeeEstimate="stripeFeeEstimate"
                        />
                        <BankTransfer
                          v-if="transferAllowed"
                          :message="message"
                          :errorMessage="errorMessage"
                          :booking="booking"
                          :getBooking="getBooking"
                        />
                        <CashPayment
                          v-if="cashAllowed"
                          :message="message"
                          :errorMessage="errorMessage"
                          :booking="booking"
                          :getBooking="getBooking"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-row>
              </v-card-text>
            </div>
          </v-card>
          <div v-if="!booking && !loading">
            <v-alert text type="error">Booking not found.</v-alert>
          </div>
        </v-container>
      </v-col>
      <Footer />
    </v-row>
  </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
import axios from "axios";
import moment from "moment";
import BankTransfer from "../components/BankTransfer.vue";
import LinkedBooking from "../components/Booking/LinkedBooking.vue";
import SeatSelection from "../components/Booking/SeatSelection.vue";
import CardPayment from "../components/CardPayment.vue";
import CashPayment from "../components/CashPayment.vue";
import ChangeBooking from "../components/ChangeBooking.vue";
import ToFromLocation from "../components/Common/ToFromLocation.vue";
import Footer from "../components/Footer.vue";
import Header from "../components/Header";
import {
  DT_FORMATS,
  apiRequest,
  configEnabled,
  findTripStartTime,
} from "../helpers";

export default {
  name: "Booking",
  components: {
    Header,
    ChangeBooking,
    Footer,
    BankTransfer,
    CashPayment,
    CardPayment,
    ToFromLocation,
    LinkedBooking,
    SeatSelection,
    VueQrcode,
  },
  props: {
    uuid: String,
  },
  data: () => ({
    snackbar: false,
    snackbarText: "",
    errorSnackbar: false,
    errorSnackbarText: "",
    loading: false,
    paymentLoading: false,
    booking: null,
    ageGroups: [],
    ageGroupsLoading: false,
    otp: null,
    otpSubmitting: false,
    otpResending: false,
    configs: [],
    stripeFeeEstimate: 0,
    dialog: false,
  }),
  computed: {
    existingAgeGroups() {
      return this.ageGroups.filter((group) =>
        this.booking.customers.map((cust) => cust.ageGroupId).includes(group.id)
      );
    },
    bmlGatewayAllowed() {
      return process.env.VUE_APP_ALLOW_BML_GATEWAY === "true" ? true : false;
    },
    stripeGatewayAllowed() {
      return process.env.VUE_APP_ALLOW_STRIPE_GATEWAY === "true" &&
        !this.booking.isLocal
        ? true
        : false;
    },
    transferAllowed() {
      return process.env.VUE_APP_ALLOW_BANK_TRANSFER === "true" ? true : false;
    },
    cashAllowed() {
      return process.env.VUE_APP_ALLOW_CASH === "true" ? true : false;
    },
    cancellationPolicy() {
      const policy = process.env.VUE_APP_CANCELLATION_POLICY;
      if (!policy) return false;
      const isBlank = policy === "";
      if (isBlank) return false;
      return policy;
    },
    convenienceFee() {
      let f = 0;
      if (this.booking.isPaid) {
        f = this.booking.stripeFeeCent;
      }
      return f;
    },
  },
  methods: {
    configEnabled(name) {
      const found = this.configs.find((c) => c.name === name);
      if (found?.value === "true") return true;
      return false;
    },
    getConfigs() {
      const endpoint = "/api/getconfigs";
      const thenHandle = (resp) => {
        const configs = resp?.data?.configs;
        if (configs) {
          this.configs = configs;
          this.$store.dispatch("configure", {
            configs,
          });
        }
      };
      apiRequest({
        endpoint,
        method: "GET",
        thenHandle,
      });
    },
    computeDate(date) {
      return moment(date).format("dddd, D MMMM YYYY");
    },
    toFromString(trip) {
      let format = DT_FORMATS.time12hr;
      if (configEnabled("Use 24 hour clock", this.$store.getters.getConfigs)) {
        format = DT_FORMATS.time24hr;
      }
      return findTripStartTime(trip, this.booking.routeToStop).format(format);
    },
    decimalCurrency(number) {
      const small = number.toString();
      return (
        small.substring(0, small.length - 2) +
        "." +
        small.substring(small.length - 2)
      );
    },
    ageGroupBreakdownLeft(group) {
      let count = 0;
      this.booking.customers.forEach((cust) => {
        if (cust.ageGroupId === group.id) count++;
      });
      return `${group.name} x ${count}`;
    },
    ageGroupBreakdownRight(group, isUSD) {
      let count = 0;
      const centPerSeat = this.booking.isLocal
        ? this.booking.trip.centPerSeatLocal
        : this.booking.trip.centPerSeat;
      const laariPerSeat = this.booking.isLocal
        ? this.booking.trip.laariPerSeatLocal
        : this.booking.trip.laariPerSeat;
      this.booking.customers.forEach((cust) => {
        if (cust.ageGroupId === group.id) count++;
      });
      let seatPrice = isUSD ? centPerSeat : laariPerSeat;
      if (seatPrice === 0) return "";
      if (this.booking.returnTripId) {
        const centPerSeatReturn = this.booking.isLocal
          ? this.booking.returnTrip.centPerSeatLocal
          : this.booking.returnTrip.centPerSeat;
        const laariPerSeatReturn = this.booking.isLocal
          ? this.booking.returnTrip.laariPerSeatLocal
          : this.booking.returnTrip.laariPerSeat;
        seatPrice += isUSD ? centPerSeatReturn : laariPerSeatReturn;
      }
      return `${
        group.occupiesSeat ? this.decimalCurrency(seatPrice * count) : "0.00"
      } ${isUSD ? "USD" : "MVR"}`;
    },
    isBeforeNow(time) {
      return moment(moment()).isBefore(time);
    },
    message(message) {
      this.snackbarText = message;
      this.snackbar = true;
    },
    errorMessage(message) {
      this.errorSnackbarText = message;
      this.errorSnackbar = true;
      if (message === "Token expired.") {
        this.$store.dispatch("logout");
      }
    },
    getBooking() {
      this.loading = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/getbookingbyuuid?uuid=${
          this.uuid
        }`,
        method: "GET",
      })
        .then((resp) => {
          this.booking = resp.data.booking;
          if (this.booking.routeToStopId) {
            for (const stop of this.booking.trip.route.stops) {
              for (const toStop of stop.toStops) {
                if (this.booking.routeToStopId === toStop.id) {
                  this.booking.trip.fromLocation = stop.location;
                  this.booking.trip.toLocation = toStop.location;
                  this.booking.routeToStop = toStop;
                }
              }
            }
          }
          this.stripeFeeEstimate = resp.data.stripeFeeEstimate;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submitOTP() {
      this.otpSubmitting = true;
      const data = {
        uuid: this.uuid,
        otp: parseInt(this.otp),
      };
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/verifyotp`,
        method: "POST",
        data,
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.otp = null;
          this.getBooking();
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.otpSubmitting = false;
        });
    },
    resendOTP() {
      this.otpResending = true;
      const data = {
        uuid: this.uuid,
      };
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/resendotp`,
        method: "POST",
        data,
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getBooking();
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.otp = null;
          this.otpResending = false;
        });
    },
    getAgeGroups() {
      this.ageGroupsLoading = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/getagegroups`,
        method: "GET",
      })
        .then((resp) => {
          this.ageGroups = resp.data.ageGroups.map((group) => ({
            ...group,
            quantity: group.name === "Adult" ? 1 : 0,
          }));
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.ageGroupsLoading = false;
        });
    },
  },
  mounted() {
    this.getConfigs();
    this.getBooking();
    this.getAgeGroups();
  },
  watch: {},
};
</script>

<style scoped>
.noHorizontalPadding {
  padding-left: 0px;
  padding-right: 0px;
}

.noVerticalPadding {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
